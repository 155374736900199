getColor(sector, type = 'normal', isHover = false) {

  inHover = #000000
  if isHover == true {
    inHover = #0f0f0f;
  }

  coefficient = ('top' #000000), ('side' #1a1515), ('front' #2d221f);

  types = ('normal' #cce6ed), ('select' #a3ebbe), ('blocked' #d6b8be), ('platform' #EFEFEF);

  for $i in 0..length(types) {
    for $j in 0..length(coefficient) {
      if type == types[$i][0] and sector == coefficient[$j][0] {
        return types[$i][1] - coefficient[$j][1] - inHover;
      }
    }
  }
}

.map-of-shop-bl {

  @media screen and (max-width: 768px) {
    display: block;
    padding-top: 2em;
    padding-bottom: 2em;
  }

  .center-bl {
    margin-bottom: 25px;
  }
}

sides = 'top' 'side' 'front';

#map_box {
  width: 100%;

  .map_box {
    .floor {
      .platform__ {
        for $item in sides {
          &{$item} {
            fill: getColor($item, 'platform');
            stroke: #E5E5E5;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 0;
          }
        }
      }
      .shop {
        .outlet__ {
          for $item in sides {
            &{$item} {
              cursor: pointer;
              fill: getColor($item, 'normal');
              &.hover {
                fill: getColor($item, 'normal', true);
              }
              &.select {
                .map_box_admin & {
                  fill: getColor($item, 'select');
                }
                .map_box_site & {
                  fill: getColor($item, 'normal', true);

                  @media screen and (max-width: 1023px) {
                    fill: darken(getColor($item, 'normal', true), 20%);
                  }
                }
              }
              &.blocked {
                fill: getColor($item, 'blocked');
              }
            }
          }
        }
        &__logo {
          fill: #FFFFFF;
          cursor: pointer;
        }
      }
      .legend {
        &.hide {
          display: none;
        }
        &__balloon {
          fill: #999999;
        }
        &__icon {
          fill: #FFFFFF;
        }
      }

      &.first {
        &.hide {
          & > .platform, & > .shop {
            .outlet__,
            .platform__ {
              for $item in sides {
                &{$item} {
                  fill: getColor($item, 'platform') !important;
                }
              }
            }
            * {
              stroke: #E5E5E5;
              stroke-linecap: round;
              stroke-linejoin: round;
              stroke-miterlimit: 10;
            }
          }
          .legend {
            display: none;
          }
          .miniature {
            .platform .platform,
            .shop .outlet__ {
              for $item in sides {
                &{$item} {
                  fill: getColor($item, 'platform');
                }
              }
            }
          }
        }
      }
      &.second {
        & > .platform {
          display: block;
          fill: rgba(255, 255, 255, .1);
        }
        &.hide {
          & > .platform {
            display: none;
          }
          & > .shop, & > .logo, & > .legend {
            * {
              display: none;
            }
          }
          .miniature {
            .platform, .shop .outlet__ {
              for $item in sides {
                &{$item} {
                  fill: getColor($item, 'platform');
                }
              }
            }
          }
        }
      }
    }
  }
}

.cube {
  min-width: 96px;
  position: relative;
  width: 10%;
  text-align: center;
  float: left;

  polyline {
    {"^[0] .top ^[-1..1]"} {
      fill: #cce6ed;
    }
    {"^[0] .side ^[-1..1]"} {
      fill: #b2d1d8;
    }
    {"^[0] .front ^[-1..1]"} {
      fill: #9fc4ce;
    }
  }

  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;

  & > div {

    position: relative;
    left: 5%;
    width: 90%;
    text-align: center;
    cursor: pointer;

    svg {
      width: 50px;
      padding: 20px 12px 18px;
      border-radius: 15px;
    }

    &:hover, &:focus, &.active {
      svg {
        background-color: #eee;
      }
    }
    &:hover, &:focus {
      span {
        text-decoration: underline;
      }
    }
    &.active {
      span {
        color: #999;
      }
    }
  }
}

.thumb {
  width: 100% !important;
}

.row {
  &.around-xs {
    &.center-xs {
      &.legend-bl {
        .col-xs-4 {
          margin-top: 20px;
        }
      }
      .col-md-1 {
        color: #888;
        position: relative;

        svg {
          width: 30px;
          height: 30px;
          fill: #ccc;
        }

        .text {
          width: 125px;

          @media screen and (min-width: 1280px) {
            position: absolute;
            left: -23.5px;
          }
          @media screen and (max-width: 1279px)  and (min-width: 1024px) {
            position: relative;
            left: -32.5px;
          }
          @media screen and (max-width: 1023px)  and (min-width: 425px) {
            margin-left: auto;
            margin-right: auto;
          }
          @media screen and (max-width: 424px) and (min-width: 375px) {
            margin-left: auto;
            margin-right: auto;
          //position: relative;
          //left: -8.5px;
          }
          @media screen and (max-width: 374px) {
            margin: 0px auto 20px;
          }
        }
      }
    }
  }
}

.bubble {
  width: 240px;
  height: 100px;
  padding: 5px;
  background: #111;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 10px;

  .tooltip {
    &-shop-name {
      text-transform: uppercase;
      font-size: 1.4em;
      font-weight: bolder;

      a {
        text-decoration: none;
        color: #fff;
        cursor: pointer;

        &:hover, &:focus {
          text-decoration: underline;
          color: #ddd;
        }
      }
    }
    &-descr,
    &-phone,
    &-schedule {
      margin-top: .5em;
      font-size: 1.3em;
      color: #aaa;
    }
    &-descr {
      margin-bottom: 1.8em;
    }
  }

  &:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 10px 15px 0;
    border-color: #111 transparent;
    display: block;
    width: 0;
    z-index: 1;
    bottom: -10px;
    left: 115px;
  }
}

.map_box_site {
  position: relative;
  .btns {
    position: absolute;
    top: 0;
    left: 35%;

    .btn {
      font-size: 1em;
      padding: .5em 2em;
      @media screen and (max-width: 1023px) {
        font-size: .75em;
        padding: .5em 2em;
      }
      @media screen and (max-width: 767px) {
        font-size: .85em;
        padding: .25em 1em;
      }
      @media screen and (max-width: 424px) {
        font-size: .75em;
        padding: .25em .75em;
      }
      @media screen and (max-width: 374px) {
        font-size: .65em;
        padding: .25em .75em;
      }

      display: block;
      min-width: 3em;
      background-color: #aaa;

      &:hover, &:focus {
        color: #fff;
      }
      &:first-child {
        &.active, &:hover {
          background-color: #f00;
        }
      }
      &:last-child {
        &.active, &:hover {
          background-color: #ffd203;
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .qtip.qtip-default.qtip-dark.qtip-rounded {
    display: none !important;
  }

  .hide-sm {
    display: none;
  }
}

@media screen and (max-width: 767px) {

  .feedback-form {
    margin-top: 275px !important;
  }

  .map-bl {
    top: 0 !important;

    #map {
      display: none !important;
    }
    img {
      display: block !important;
      width: 100%;
    }
  }

}

@media screen and (max-width: 767px) {
  #mobileTooltip {
    margin: 10px;
    padding: 10px;
    display: none;
    border-radius: 6px;
    position: relative;
    background-color: rgba(0, 0, 0, .8);

    &.show {
      display: block;
    }

    span.close {
      top: 2px;
      right: 10px;
      color: #fff;
      position: absolute;
    }

    div {
      color: #aaa;

      a {
        color: #fff;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  #mobileTooltip {
    display: none;
  }
}

#photo-slider-mob {
  height: 3000px !important;
}

@media screen and (max-width: 767px) {
  #photo-slider {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  #photo-slider-mob {
    display: none !important;
  }
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
  width: 100vw;

  &.xs-hide {
    @media screen and (max-width: 767px) {
      display: none !important;
    }
  }
  &.xs-show {
    display: none !important;
    @media screen and (min-width: 767px) {
      display: table !important;
    }
  }
}

@media screen and (max-width: 1023px) {
  .col-xs-6.col-sm-3.col-md-1 {
    margin-bottom: 20px;
  }
}
